import {useState, useEffect} from "react";
import {useNavigate, useLocation, useSearchParams} from "react-router-dom";
import LoginPresenter from "./LoginPresenter";
import {useCookies} from "react-cookie";
import {webProxyApi} from "api/WebProxyApi";
import {NvrInfoApi} from "api/NvrInfoApi";
import {useAuth} from "context/auth";
import LoadingOverlay from 'react-loading-overlay'


const LoginContainer = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const auth = useAuth();
    const [searchParams] = useSearchParams();
    const query = searchParams.get('host');

    //console.log(query);

    const [state, setState] = useState({
        host: query,
        userID: "",
        password: "",
    });
    const [isActive, setLoading] = useState(null);

    const domain = window.location.hostname

    // //ws server Ip
    // const [wsIp, setWsIp] = useState(null);
    // //ws server Port
    // const [wsPort, setWsPort] = useState(0);
    // //ws server Ip
    // const [webIp, setWebIp] = useState(null);
    // //ws server Port
    // const [webPort, setWebPort] = useState(0);
    //
    // const [channel, setChannel] = useState(9);

    // 쿠키에 userId 저장
    const [cookies, setCookie, removeCookie] = useCookies(["rememberUserId", "rememberHost"]);

    // connect 가능
    const [isValidated, setIsValidated] = useState(true);
    const [isHostValidated, setIsHostValidated] = useState(true);
    // remember ID
    const [isRemember, setIsRemember] = useState(false);

    const [isVersion, setIsVersion] = useState(true);
    const [isType, setIsType] = useState(true);

    const inputLists = [
        {
            label: "Host",
            name: "host",
            placeholder: "Enter host",
            value: state.host,
            info: true,
            contents: (
                <div>
                    The IP address, URL, or pathfinder ID for the recorder you'd like to
                    connect to. Please use Pathfinder to access your recorder in the private network. <br/> <br/>
                    <strong>Examples</strong>
                    <br/> IP ADDRESS: 127.0.0.1:[Port]
                    <br/> URL: usa.mydwddns.net:[Port]
                    <br/> Pathfinder ID: P2P-0123456789AB-001122
                </div>
            ),
        },
        {
            label: "User ID",
            name: "userID",
            placeholder: "Enter user ID",
            value: state.userID,
        },
        {
            label: "Password",
            name: "password",
            placeholder: "Enter password",
            value: state.password,
            type: "password",
        },
    ];

    // input onChange 함수
    const onChange = (e) => {
        let {
            target: {value, name},
        } = e;

        setState({
            ...state,
            [name]: value,
        });
    };

    // remember id checkbox onchange
    const onChangeRemember = (e) => {
        setIsRemember(e.target.checked);
        if (!e.target.checked) {
            removeCookie("rememberUserId");
            removeCookie("rememberHost");
        }
    };


    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    }


    // connect 이벤트
    const onSubmit = async () => {
        if (state.host !== "" && state.userID !== "" && state.password !== "") {
            setLoading(true);
            setIsHostValidated(true);
            setIsValidated(true);
            setIsType(true);
            setIsVersion(true);
            console.log(state.host.includes("P2P"));

            let webIp
            let webPort
            let channel = 16;
            // login api success 후 실행될 이벤트들
            if (isRemember) {
                setCookie("rememberUserId", state.userID);
                setCookie("rememberHost", state.host.trim().toUpperCase());
            }

            auth.login(state.host.trim());


            if (state.host.toUpperCase().includes("P2P")) {

                try {
                    let webResult = null;
                    ({data: webResult} = await webProxyApi.webServerInfoApi(state.host.trim().toUpperCase()));


                    webIp = webResult.address;
                    webPort = webResult.port;

                    //console.log(webIp, webPort);

                    let getTimeResult = null;
                    let nvrInfoResult = null;
                    let time = null;
                    let generationUrl = null;
                    let type = null;
                    let version = null;
                    let viewerName = null;
                    let viewerVersion = null;
                    try {
                        generationUrl = `/api/moduleInformationAuthenticated`;
                        nvrInfoResult = await NvrInfoApi.getNvrInfoApi(`${webIp}:${webPort}`, generationUrl, state.userID, state.password);
                        channel = nvrInfoResult.data.reply.maxCameras;
                        type = nvrInfoResult.data.reply.type;
                        version = nvrInfoResult.data.reply.version;
                        //console.log(type, version, channel)
                        console.log(version);

                        // generationUrl = `/api/publicCmd`;
                        // nvrInfoResult = await NvrInfoApi.getNvrInfoApi(`${webIp}:${webPort}`, generationUrl, state.userID, state.password);
                        if(nvrInfoResult.data.reply?.smartWebViewer) {
                            viewerVersion = nvrInfoResult.data.reply.smartWebViewer.version;
                            viewerName = nvrInfoResult.data.reply.smartWebViewer.name;
                        }


                        if(type === "DVR"){
                            if (version <= "1.0.9.9" && version.length === 7) {
                                setIsVersion(false);
                            } else {
                                await NvrInfoApi.getTimeHostApi(`${webIp}:${webPort}`, state.userID, state.password)
                                    .then(data => {
                                        let time = data.data.time;
                                        let uid = data.data.uid;
                                        let key = data.data.key;
                                            // generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${key}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/UMiniWebViewer_1.2.5.1&logoutPage=https://${window.location.hostname}`;
                                        console.log(viewerVersion);
                                        if(viewerVersion != null && viewerVersion.substr(0, 3) === "1.2" ) {
                                            generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${key}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/pf/UMiniWebViewer_${viewerVersion}&logoutPage=https://${window.location.hostname}`;

                                        }
                                        else {
                                            generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${key}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/pf/UMiniWebViewer_1.1.4.93&logoutPage=https://${window.location.hostname}`;
                                        }


                                        console.log(generationUrl);
                                        window.location.href = generationUrl;

                                    });
                            }
                        } else if(type === "NVR"){
                            if (version.substr(0, 1) === "1" && version.substr(2) < "5.2.8" && version.length === 7) {
                                setIsVersion(false);
                            } else if (version.substr(0, 1) === "0" && version.substr(2) < "5.2.8" && version.length === 7) {
                                console.log(version.substr(2));
                                setIsVersion(false);
                            } else if( version.substr(2) < "5.3.11"){
                                setIsVersion(false);

                            } else if(version.length === 7 && version.substr(4,1) === 3 ){
                                setIsVersion(false);

                            } else {

                                await NvrInfoApi.getTimeHostApi(`${webIp}:${webPort}`, state.userID, state.password)
                                    .then(data => {
                                        let time = data.data.time;
                                        let uid = data.data.uid;
                                        let key = data.data.key;
                                        if(viewerVersion != null && viewerVersion.substr(0, 3) === "1.2" ) {
                                            //TODO TEST PF
                                            // generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${key}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/testpf/UMiniWebViewer_${viewerVersion}&logoutPage=https://${window.location.hostname}`;
                                            //TODO PF
                                            generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${key}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/pf/UMiniWebViewer_${viewerVersion}&logoutPage=https://${window.location.hostname}`;

                                        }

                                        else {
                                            //TODO TEST PF
                                            generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${key}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/testpf/UMiniWebViewer_1.2.5.1&logoutPage=https://${window.location.hostname}`;
                                            //TODO PF
                                            // generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${key}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/UMiniWebViewer_1.2.5.1&logoutPage=https://${window.location.hostname}`;

                                        }
                                        //generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${key}&smartWebViewer=https://pf-wasm.s3.us-west-1.amazonaws.com/UMiniWebViewer_1.2.x.x&logoutPage=https://${window.location.hostname}`;
                                        console.log(viewerVersion);
                                        console.log(generationUrl);
                                        window.location.href = generationUrl;

                                    });
                            }

                        } else {
                            setIsType(false);
                        }


                    } catch (error) {
                        if (error.response.status === 0) {
                            setIsVersion(false);
                            setLoading(false);
                        } else {
                            setIsValidated(false);
                            setLoading(false);
                        }
                        return;
                    }

                } catch (error) {
                    console.log(error)
                    setIsHostValidated(false);
                    setLoading(false);
                    return;
                }

                setIsValidated(true);
            } else {

                let getTimeResult = null;

                let generationUrl = null;
                try {
                    //({data: getTimeResult} = await NvrInfoApi.getTimeHostApi(state.host, state.userID, state.password));
                   await NvrInfoApi.getTimeHostApi(state.host.trim(), state.userID, state.password)
                       .then(data => {
                           if(data.data.token === undefined && data.data.error === undefined && data.data.type === undefined) {
                               console.log(data.data)
                               let time = data.data.time;
                               let uid = data.data.uid;
                               let key = data.data.key;
                               generationUrl = `http://${state.host}/cgi-bin/login_ex?login_type=1&time=${time}&uid=${uid}&key=${key}&logoutPage=https://${window.location.hostname}`;
                               window.location.href = generationUrl;
                           } else{
                               if(data.data.error === "9" || data.data.error === "2"){
                                   setIsValidated(false);
                               }else {
                                   if(data.data.type === "invalid-json"){
                                       generationUrl = `https://${state.host}`

                                       window.location.href = generationUrl;

                                   }else {
                                       let token = data.data.token;
                                       generationUrl = `https://${state.host}/static/index.html#/view?token=${token}`

                                       window.location.href = generationUrl;
                                   }
                               }
                           }
                       })


                    //window.open(generationUrl);
                } catch (error) {
                    console.log(error)
                    setIsHostValidated(false);
                    setLoading(false);
                    return;
                }
                //window.open(pageUrl);
            }


        }
        //TODO TVT
        // else if(state.host !== "" && state.userID === "" && state.password === ""){
        //     try{
        //         setLoading(true);
        //         setIsHostValidated(true);
        //         setIsValidated(true);
        //         setIsType(true);
        //         setIsVersion(true);
        //         let webResult = null;
        //         let webIp;
        //         let webPort;
        //         let generationUrl;
        //
        //         ({data: webResult} = await webProxyApi.webServerInfoApi(state.host.trim().toUpperCase()));
        //         webIp = webResult.address;
        //         webPort = webResult.port;
        //
        //         generationUrl = `http://${webIp}:${webPort}/#login`;
        //         window.location.href = generationUrl;
        //
        //
        //     } catch (error){
        //         setIsHostValidated(false);
        //         setLoading(false);
        //     }
        //     setLoading(false);
        //
        // }
        else {
            setIsValidated(false);
            setLoading(false);
            return;
        }
        setLoading(false);
    };

    useEffect(() => {
        /*저장된 쿠키값이 있으면, CheckBox TRUE 및 UserID에 값 셋팅*/
        if (cookies.rememberUserId !== undefined && !isRemember) {
            if (state.host == null) {
                setState({...state, userID: cookies.rememberUserId, host: cookies.rememberHost});
            } else {
                setState({...state, userID: cookies.rememberUserId});

            }
            setIsRemember(false);
        } else {

        }


    }, []);


    return (
        <LoadingOverlay

            active={isActive}

            spinner

            text='Connecting...'

        >
            <LoginPresenter
                inputLists={inputLists}
                onSubmit={onSubmit}
                onChange={onChange}
                onChangeRemember={onChangeRemember}
                isValidated={isValidated}
                isRemember={isRemember}
                handleKeyPress={handleKeyPress}
                isHostValidated={isHostValidated}
                isType={isType}
                isVersion={isVersion}

            />
        </LoadingOverlay>
    );
};

export default LoginContainer;
